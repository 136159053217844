
import KioskBoard from 'kioskboard';

$(document).on('click', ".clickable-row" , function() {
  window.location.href = $(this).data("href");
});


$('.adv-search').on('click', function(e) {
  $('.adv-search-fields').toggleClass("is-hidden");
  $(this).text(function(i, text){
      return text === "Show more search filters" ? "Hide search filters" : "Show more search filters";
  })
  e.preventDefault();
});


//init default carousel
$('#identity-cards').owlCarousel({
  margin:15,
  autoplay: false,
  nav: false,
  dots: true,
  loop:true,
  singleItem: true,
  responsive:{
    0:{
      items:1,
    },
    600:{
      items:1,
    },
    1000:{
      items:1,
    }
  }
});

$('.story-gallery').owlCarousel({
  loop:true,
  margin:15,
	autoWidth:true,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: false,
  nav: false,
  dots: false,
  singleItem: true,
  responsive:{
    0:{
      items:1,
    },
    600:{
      items:1,
    },
    1000:{
      items:2,
    }
  }
});

$('.story-gallery').each(function () {
  var $mfp = $(this);

  $mfp.on('click', '.owl-item.cloned a', function (event) {
    event.preventDefault();
    var self = $(this);

    // Dependency on image positions in owl and on unique a.href attrs
    $mfp.find('.owl-item:not(.cloned) a').each(function (index) {
      if ($(this).attr('href') === self.attr('href')) {
        $mfp.magnificPopup('open', index);
       }
    });
  });

  $mfp.magnificPopup({
    type: 'image',
    delegate: '.owl-item:not(.cloned) a',
    gallery: {
      enabled: true
    }
  });
});

$('#identity-cards').each(function () {
  var $mfp = $(this);
  $mfp.on('click', '.owl-item.cloned a', function (event) {
    event.preventDefault();
    var self = $(this);

    // Dependency on image positions in owl and on unique a.href attrs
    $mfp.find('.owl-item:not(.cloned) a').each(function (index) {
      if ($(this).attr('href') === self.attr('href')) {
        $mfp.magnificPopup('open', index);
      }
    });
  });

  $mfp.magnificPopup({
    type: 'image',
    delegate: '.owl-item:not(.cloned) a',
    gallery: {
      enabled: true
    }
  });
});

  var btn = document.querySelector('#showModal');
  if (btn) {
    var modalDlg = document.querySelector('#emailModal');
    var modalCloseBtn = document.querySelector('.model-close-btn');
    btn.addEventListener('click', function(){
      modalDlg.classList.add('is-active');
    });

    modalCloseBtn.addEventListener('click', function(){
      modalDlg.classList.remove('is-active');
    });
  }

$(window).bind("load",function () {
  var keys = {
    bksp: 8,
    tab: 9,
    enter: 13,
    space: 32,
    delete: 46
  };

  $('#nationality')
    .select2({
      placeholder: "Select a country"
  })
  .on("select2:open", function (e) {
    if( window.innerHeight == screen.height) {
      $('.select2-container--open .select2-search__field').keyboard({
        // Used by jQuery UI position utility
        position: {
          // null = attach to input/textarea;
          // use $(sel) to attach elsewhere
          of: $(document),
          my: 'center bottom',
          at: 'center bottom',
          // used when "usePreview" is false
          at2: 'center bottom'
        },
        reposition: true,
        usePreview: false,
        change: function(e, keyboard, el) {
          var key = (keyboard.last.key || '').toLowerCase();
          // trigger a keydown for "special" keys
          e.type = keys[key] ? 'keydown' : 'input';
          e.which = keys[key] || key.charCodeAt(0);
          keyboard.$el.trigger(e);
        }
      })
      // activate the typing extension
      .addTyping({
        showTyping: true,
        delay: 50
      });
    }
  });
  $('#ybirth')
    .select2({
      placeholder: "Select an Year"
    })
  .on("select2:open", function (e) {
    if( window.innerHeight == screen.height) {
      $('.select2-container--open .select2-search__field').keyboard({
        // Used by jQuery UI position utility
        position: {
          // null = attach to input/textarea;
          // use $(sel) to attach elsewhere
          of: $(document),
          my: 'center bottom',
          at: 'center bottom',
          // used when "usePreview" is false
          at2: 'center bottom'
        },
        reposition: true,
        usePreview: false,
        change: function(e, keyboard, el) {
          var key = (keyboard.last.key || '').toLowerCase();
          // trigger a keydown for "special" keys
          e.type = keys[key] ? 'keydown' : 'input';
          e.which = keys[key] || key.charCodeAt(0);
          keyboard.$el.trigger(e);
        }
      })
      // activate the typing extension
      .addTyping({
        showTyping: true,
        delay: 50
      });
    }
  });
});


$(document).ready(function() {
  $('#id-share-form').on('submit',function(){

    // Add text 'loading...' right after clicking on the submit button.
    $('#id-share-form-submit').html('Sending Email...');
    $('.output_message').text('');
    var form = $(this);
    $.ajax({
      url: "/sendEmail.php",
      method: form.attr('method'),
      data: form.serialize(),
      success: function(result){
        if (result == 'success'){
          // $('#id-share-form').css('display', 'none');
          $("#id-share-form").fadeOut("fast");
          if($('.output_message').hasClass("error")){
            $('.output_message').removeClass("error");
          }
          $('.output_message').addClass('success');
          $('.output_message').text('Shared via email sucessfully!').fadeIn("slow").css("display", "inline-block");
          $('#id-share-form')[0].reset();
          $('#id-share-form-submit').html('Submit');
        } else {
          $('#id-share-form-submit').html('Submit');
          if($('.output_message').hasClass("success")){
            $('.output_message').removeClass("success");
          }
          $('.output_message').addClass('error');
          $('.output_message').text(result);
        }
      }
    });

    // Prevents default submission of the form after clicking on the submit button.
    return false;
  });

  // Reset the form once the the model closed.
  $('#emailModal .model-close-btn').on('click', function(){
    $('#id-share-form')[0].reset();
    $("#id-share-form").fadeIn("fast");
    $('.output_message').fadeOut("fast");
    $('#id-share-form-submit').html('Submit');
  });

});
